<template>
  <b-form-checkbox-group v-model="selectedIds">
    <div>
      <div v-if="notificationsList.length > 0">
        <b-row>
          <b-col cols="12" class="mt-2">
            <b-card no-body class="card">
              <template
                #header
                style="display: flex; justify-content: space-between"
              >
                <div>
                  <b-form-checkbox v-model="allSelected" @change="selectAll"
                    >تحديد الكل
                  </b-form-checkbox>
                </div>
                <b-button
                  variant="transparnt"
                  class="p-0"
                  @click="deleteNotification"
                >
                  <unicon fill="gray" height="18px" name="trash-alt"> </unicon>
                </b-button>
              </template>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            v-for="notifications in notificationListData"
            :key="notifications.id"
          >
            <b-card style="margin-top: -25px" class="card">
              <template
                #header
                style="display: flex; justify-content: space-between"
              >
                <div>
                  <b-form-checkbox :value="notifications.id"> </b-form-checkbox>
                  {{ notifications.title }}
                </div>
                <div>
                  <b-badge
                    pill
                    variant="light-primary"
                    class="mr-1"
                    v-if="notifications.sendTo == 1"
                    >تطبيق الطلاب</b-badge
                  >
                  <b-badge
                    pill
                    class="mr-1"
                    variant="light-warning"
                    v-if="notifications.sendTo == 2"
                    >تطبيق نقاط البيع</b-badge
                  >
                  <!-- <strong>
                    {{ new Date(notification.date).toISOString().substr(0, 10) }}
                  </strong> -->
                </div>
                <div>
                  {{ formDate(notifications.date) }}
                  <b-button
                    variant="transparnt"
                    class="p-0"
                    @click="goDetails(notifications.id)"
                  >
                    <unicon fill="gray" height="20px" name="ellipsis-v">
                    </unicon>
                  </b-button>
                </div>
              </template>
              <b-card-text>
                {{ notifications.body }}
              </b-card-text>
            </b-card>
          </b-col>
          <b-col cols="12" class="d-flex justify-content-center">
            <ek-pagination
              :items="notificationsList"
              v-model="notificationListData"
              :pageLength="5"
            />
          </b-col>
        </b-row>
      </div>
      <div v-else class="text-center mt-2">
        <h4>لا يوجد اشعارات</h4>
      </div>
    </div>
  </b-form-checkbox-group>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState({
      notifications: ({ notifications }) => notifications.notifications,
    }),
    ...mapGetters(["notificationsList"]),
  },
  data: () => ({
    notificationListData: [],
    selectedIds: [],
    allSelected: false,
  }),
  methods: {
    ...mapActions(["getNotificationsList", "deleteNotificationsRang"]),
    formDate(date) {
      let dete = `${new Date(date).toLocaleDateString()}`;
      return dete;
    },
    getName(num) {
      if (num == 1) return "تطبيق الطالب";
      else if (num == 2) return "تطبيق نقطة البيع";
    },
    goDetails(id) {
      this.$router.push(`/admin/notifications/${id}`);
    },
    deleteNotification() {
      this.deleteNotificationsRang(this.selectedIds);
    },
    selectAll() {
      if (this.allSelected) {
        const selectedIds = this.notifications.map((u) => u.id);
        this.selectedIds = selectedIds;
      } else {
        this.selectedIds = [];
      }
    },
  },
  created() {
    this.getNotificationsList();
  },
};
</script>
<style scoped>
.card {
  border-radius: 0px;
}
.card .card-header {
  padding: 0.5rem !important;
}
</style>